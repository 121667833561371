import React from 'react'
import { Wrapper } from './styles'
import { MdSearch,MdExitToApp,MdHighlightOff } from "react-icons/md";

export default (props) => {
    return <Wrapper>
        <div className="input-wrapper">
            <MdSearch />
            <input 
                type="textbox"
                placeholder="ÖRN:İSİM_SOYİSİM" 
                onChange={(e) => props.change(e)} />
        </div>
        <div className="input-wrapper">
            {/* <MdDateRange /> */}
            <input type="date" onChange={(e) => props.startDate(e)}/>
        </div>
        <div className="input-wrapper">
            {/* <MdDateRange /> */}
            <input type="date" onChange={(e) => props.endDate(e)}/>
        </div>
        <div className="input-wrapper" style={{background:`${props.disabledvalue?'#1dd1a1':'red'}`}}>
            {props.disabledvalue?<MdExitToApp onClick={(e)=>props.run(e)}/>:<MdHighlightOff/>}
        </div>
    </Wrapper>
}