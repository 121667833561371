import React,{useContext} from 'react';
import {
  Route,
  Redirect,
} from "react-router-dom";
import { UserContext } from '../store/auth-context';
export default function PrivateRoute({ children, ...rest }) {
  const authDetails = useContext(UserContext);
  return (
    <Route
          {...rest}
          render={({ location }) =>
          authDetails.isAuth ? (
              children
            ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: location }
                  }}
                />
              )
          }
        />
        );
}
