import React from 'react';
import { Wrapper } from './styles';

export default function Home(props) {
    return <Wrapper>
        <article>
        <p>Antalya Patoloji Merkezi Kurum Rapor Sayfasına hoşgeldiniz. Kurumunuza ait raporlarınızı "Raporlar" linki üzerinden oturum açarak indirebilirsiniz.</p>
        <p>Rapor ekranınız ilk açıldığı anda son 30 günlük raporlarınız listelenmektedir.</p>
        <p>30 günden eski raporlarınızı görüntülemek için rapor ekranının en üstünde bulunan iki adet tarih alanını gerekli tarih aralığını girerek görüntüleyebilirsiniz.</p>
        <p>İsim ile raporunuza erişmek isterseniz, rapor ekranınızın en üst sol tarafında bulunan büyüteç simgeli alana hasta adını "BÜYÜK HARF" ve isim soyisim arasına "_" alt çizgi eklemeniz gerekmektedir.</p>
        </article>
    </Wrapper>
}