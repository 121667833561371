import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { UserProvider } from './store/auth-context';
import Home from './pages/home'
import Rapor from './pages/rapor'
import HastaKayit from './pages/hastaKayit'
import LoginPage from './pages/login'
import Header from './components/header'
import GlobalStyle from './globalStyles';
import { Fragment } from 'react';
import PrivateRoute from './utils/privateroute'
import Footer from './components/footer'
import Content from './components/content'
export default function App() {
  return (<Fragment>
    <GlobalStyle />
    <Router>
      <UserProvider>
        <Header />
        <Content>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <PrivateRoute path="/rapor">
              <Rapor />
            </PrivateRoute>
            <PrivateRoute path="/hastakayit">
              <HastaKayit />
            </PrivateRoute>
          </Switch>
        </Content>
      </UserProvider>
    </Router>
    {/* <Footer /> */}
  </Fragment>
  );
}






