import React from 'react';
import { Link } from "react-router-dom";
import { Header } from './styles'
import Logo from '../../assets/logo.png'
export default (props) => {
    return <Header>
        <div className="logo"><img src={Logo} alt="APM_logo"/></div>
        <ul>
            <li>
                <Link to="/">Ana Sayfa</Link>
            </li>
            <li>
                <Link to="/rapor">Raporlar</Link>
            </li>
            <li>
                <Link to="/hastakayit">Hasta Kayıt</Link>
            </li>
        </ul>
    </Header>
}