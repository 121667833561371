import React, { useContext,useState } from 'react';
import axios from 'axios'
import qs from 'qs'
import { useHistory, useLocation } from "react-router-dom";
import { APIURL } from '../../store/url-context';
import { UserContext, UserDispatchContext } from '../../store/auth-context';
import { Wrapper } from './styles';
export default (props) => {
  const[username,setUsername] = useState("");
  const[password,setPassword] = useState("");
  let history = useHistory();
  let location = useLocation();
  let contextType = useContext(UserContext)
  const setUserDetails = useContext(UserDispatchContext);
  let { from } = location.state || { from: { pathname: "/" } };
  function oturumAc(e) {
    e.preventDefault();
    let data = qs.stringify({
      'webusername': username,
     'webpassword': password 
     });
     let config = {
       method: 'post',
       url: `${APIURL}login`,
       headers: { 
         'Authorization': 'Basic dXNlcm5hbWU6cGFzc3dvcmQ=', 
         'Content-Type': 'application/x-www-form-urlencoded'
       },
       data : data
     };
     
     axios(config)
     .then(function (response) {
       setUserDetails({ ...contextType.userDetails, isAuth: response.data.Oturum ,user:response.data.data,isPermission:response.data.Yetki});
       if(response.data.Oturum)history.replace(from)
     })
     .catch(function (error) {
       console.log(error);
     });
     
  }
  return (<UserContext.Consumer>
    {userContext => {
      return <Wrapper>
        <p>Raporlarınızı görüntülemek için lütfen oturum açınız.</p>
        <form>
          <input 
            type="textbox" 
            name="username"
            placeholder="Kullanıcı Adı" 
            onChange={(e)=>setUsername(e.target.value)}/>
          <input 
            type="textbox" 
            name="password"
            placeholder="Şifre"  
            onChange={(e)=>setPassword(e.target.value)}/>
          <button onClick={(e) => oturumAc(e)}>Oturum Aç</button>
        </form>

      </Wrapper>
    }}
  </UserContext.Consumer>

  );
}