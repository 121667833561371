import styled from 'styled-components';

export const Wrapper= styled.footer`
display:flex;
/* padding:40px 90px 10px; */
box-sizing:border-box;
/* height:250px; */
color:white;
flex-direction:row;
flex-wrap:wrap;
ul{
    li{
        list-style:none;
    }
}
.copyright{
    text-align:center;
    flex-basis:100%;
    padding:30px 90px 20px;
    background:rgba(0,34,104,0.9)
}
`
export const Left = styled.div`
display:flex;
flex-direction:row;
flex-basis:50%;
padding:40px 10px 40px 90px;
box-sizing:border-box;
background:#002268;
@media (max-width: 768px) {
    flex-basis:100%;
    padding:20px;
  } 
ul{
    padding:0;
    margin:0;
    display:flex;
    flex-direction:column;
    flex-basis:100%;
    li{
        padding:5px;
    }
}
`
export const Right = styled.div`
display:flex;
flex-direction:row;
flex-basis:50%;
@media (max-width: 768px) {
    flex-basis:100%;
  } 
iframe{
    flex-basis:100%;
    height:100%;
}
`