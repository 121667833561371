import styled from 'styled-components';

export const Header = styled.header`
display:flex;
flex-direction:row;
justify-content:space-between;
padding:0px 90px;
background:#002268;
.logo{
    width:80px;
    img{
        width:100%;
    }
}
ul{
    margin:0px;
    padding:0px;
    display:flex;
    flex-direction:row;
    li{
        list-style:none;
        padding:5px 20px;
        box-sizing:border-box;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-content:center;
        align-items:center;
        a{
            text-decoration:none;
            color:white;
            &:visited{
                color:white;
            }
        }
    }
}
`