export const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, []);
};

export const groupByHastaRaporId = function (r, a) {
    r[a.HastaRaporID] = r[a.HastaRaporID] || [];
    r[a.HastaRaporID].push(a)
    return r;
};
export const flatten = function (arr) {
    return arr.reduce(function (flat, toFlatten) {
        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
}