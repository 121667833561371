import styled from 'styled-components';
export const Wrapper = styled.div`
 display:flex;
 flex-basis:20%;
 margin:10px 0px;
 flex-direction:row;
 justify-content:space-evenly;
 .input-wrapper{
   background:#ecf0f1;
   border-radius:4px;
   padding:5px 10px;
   display:flex;
   margin:0px 5px;
   box-sizing:border-box;
   &:last-child{
      background:#002268;
      color:white;
      &:disabled{
         background:white;
         color:grey;
      }
      .colorred{
         background:red;
      }
   }
   &:hover{
      svg{
         opacity:1;
      }
   }
   svg{
      object-position:center;
      height:100%;
      opacity:.6;
   }
 }
 input{
    padding:5px 10px;
    box-sizing:border-box;
    border:none;
    background:#ecf0f1;
    border-radius:2px;
    outline:none;
 }
`