import styled from 'styled-components';

export const Wrapper = styled.section`
display:flex;
/* padding:20px 90px; */
flex-direction:column;
justify-content:center;
align-items:stretch;
/* width:50%; */
flex-basis:50%;
box-sizing:border-box;
@media (max-width: 768px) {
    flex-basis:100%;
    width:100%;
    padding:10px;
  } 
ul{
    margin:0;
    padding:0;
    overflow:auto;
    /* flex-basis:80%; */
    min-height:50vh;
    max-height:80vh;
    display:flex;
    flex-direction:column;
    box-sizing:border-box;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    ::-webkit-scrollbar-thumb {
      background: #95a5a6; 
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #7f8c8d; 
    }
    li{
        list-style:none;
        margin:10px;
        background-color:white;
        padding:10px;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        flex-wrap:wrap;
        box-sizing:border-box;
        .wrap{
          flex-basis:100%;
          display:flex;
          flex-direction:row;
          justify-content:space-between;
          /* flex-wrap:wrap; */
          padding:10px;
          box-sizing:border-box;
          @media (max-width: 768px) {
            flex-wrap:wrap;
          } 
          &:nth-child(odd) {
            background:#fbfbfb;
            }
            &:nth-child(even) {
              background:#ecf0f1;
              
            }
          .kapsa{
            display:flex;
            flex-direction:column;
            flex-basis:100%;
          }
          article{
            cursor:pointer;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            /* flex-basis:80%; */
            padding:10px;
            box-sizing:border-box;
            @media (max-width: 768px) {
              flex-wrap:wrap;
            } 
            &:hover{
              background:#002268;
              color:white;
              &:nth-child(odd) {
              color:white;
            }
            &:nth-child(even) {
              color:white;
            }
            svg{
                color:white;
            }
            }
            svg{
                margin:0 5px;
                width:20px;
                height:20px;
            }
            &:nth-child(odd) {
              color:red;
            }
            &:nth-child(even) {
              color:black;
            }
            .button-group{
                button{
                border:none;
                background:none;
                color:red;
                margin:0;
                padding:0;
                display:flex;
                flex-direction:column;
                align-items: center;
                &:first-child{
                    color:#002268;
                }
                svg{
                    margin:0px 2px;
                    width:20px;
                    height:20px;
                }
            }
        }
          }
        }
        
        .deleteButton{
          border:none;
            background:none;
            color:red;
            margin:0;
            padding:0;
            svg{
                margin:0px;
                width:20px;
                height:20px;
            }
        }
        
        
        
    }
}
`