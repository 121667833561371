import React, { useState, useEffect, useContext } from 'react';
import { Wrapper } from './styles';
import filedownload from 'react-file-download';
import { UserContext } from '../../store/auth-context';
import { APIURL } from '../../store/url-context';
import { MdPictureAsPdf, MdFileDownload, MdDelete } from "react-icons/md";
import { FaSignature } from "react-icons/fa";
import Filter from '../../components/filter'
import axios from 'axios'
import qs from 'qs'
import { groupBy, groupByHastaRaporId, flatten } from '../../utils/functions'

export default function Home(props) {
    let contextType = useContext(UserContext)
    const [raporlist, setRaporList] = useState(false);
    const [filterraporlist, setFilterRaporList] = useState(false);
    const [startDate, setStartDate] = useState(false);
    const [endDate, setEndDate] = useState(false);
    
    function _getList() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic dXNlcm5hbWU6cGFzc3dvcmQ=");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        if (contextType.isPermission) {
            fetch(`${APIURL}rapor/`, requestOptions)
                .then(res => res.json())
                .then(list => {
                    let hastaRaporGroup = list.data.reduce(groupByHastaRaporId, []); // apidan gelen ham veriyi hastarapor ıd ye göre grupluyor
                    let indexChange = Object.keys(hastaRaporGroup).map(key => hastaRaporGroup[key]); //array deki uzun id indexleri nin 0,1,2.. gibi yapıyor
                    let hastaRevisionGroup = indexChange.map(x => groupBy(x, "Revision")) //hasta rapor arraylerinin içindeki raproları revision numarasına göre grupluyor
                    return hastaRevisionGroup
                }).then((data) => {
                    setFilterRaporList(data);
                    setRaporList(data);
                })
        } else {
            fetch(`${APIURL}rapor/kurum/${contextType.user.ID}`, requestOptions)
                .then(res => res.json())
                .then(list => {
                    let hastaRaporGroup = list.data.reduce(groupByHastaRaporId, []); // apidan gelen ham veriyi hastarapor ıd ye göre grupluyor
                    let indexChange = Object.keys(hastaRaporGroup).map(key => hastaRaporGroup[key]); //array deki uzun id indexleri nin 0,1,2.. gibi yapıyor
                    let hastaRevisionGroup = indexChange.map(x => groupBy(x, "Revision")) //hasta rapor arraylerinin içindeki raproları revision numarasına göre grupluyor
                    return hastaRevisionGroup
                }).then((data) => {
                    setFilterRaporList(data);
                    setRaporList(data);
                })
        }
    }
    function _indir(x) {
        axios({
            method: 'get',
            url: `${APIURL}rapor/kurum/indir/${x}`,
            responseType: 'arraybuffer',
            headers: {
                'authorization': 'Basic dXNlcm5hbWU6cGFzc3dvcmQ='
            }
        })
            .then(function (response) {
                filedownload(response.data, decodeURI(response.headers.filename));
            });
    }
    function _sil(x) {
        let config = {
            method: 'post',
            url: `${APIURL}raporsil`,
            headers: {
                'Authorization': 'Basic dXNlcm5hbWU6cGFzc3dvcmQ=',
                'Content-Type': 'application/json'
            },
            data: { list: x }
        };
        axios(config)
            .then(function (response) {
                console.log(response)
                _getList();
                // filedownload(response.data, "adasd.pdf");
            });
    }
    function _filter(event) {
        let filterRapor = flatten(raporlist).filter(d => d.FileName.indexOf(event.target.value) !== -1);
        let hastaRaporGroup = filterRapor.reduce(groupByHastaRaporId, []); // apidan gelen ham veriyi hastarapor ıd ye göre grupluyor
        let indexChange = Object.keys(hastaRaporGroup).map(key => hastaRaporGroup[key]); //array deki uzun id indexleri nin 0,1,2.. gibi yapıyor
        let hastaRevisionGroup = indexChange.map(x => groupBy(x, "Revision")) //hasta rapor arraylerinin içindeki raproları revision numarasına göre grupluyor

        setFilterRaporList(hastaRevisionGroup)
    }
    function _startDate(event) {
       
        setStartDate(event.target.value)
    }
    function _endDate(event) {
       
        setEndDate(event.target.value)
    }
    function _run(event) {;
        let data;
        if(contextType.isPermission){
             data = qs.stringify({
                'id':false,
                'startDate':startDate,
                'endDate':endDate
               });
        }else{
             data = qs.stringify({
                'id':contextType.user.ID,
                'startDate':startDate,
                'endDate':endDate
               });
        }
        
           let config = {
             method: 'post',
             url: `${APIURL}rapor/kurum/tarih`,
             headers: { 
               'Authorization': 'Basic dXNlcm5hbWU6cGFzc3dvcmQ=', 
               'Content-Type': 'application/x-www-form-urlencoded'
             },
             data : data
           };
           
           axios(config)
           .then(res => res)
                .then(res => {
                    console.log(res)
                    let hastaRaporGroup = res.data.data.reduce(groupByHastaRaporId, []); // apidan gelen ham veriyi hastarapor ıd ye göre grupluyor
                    let indexChange = Object.keys(hastaRaporGroup).map(key => hastaRaporGroup[key]); //array deki uzun id indexleri nin 0,1,2.. gibi yapıyor
                    let hastaRevisionGroup = indexChange.map(x => groupBy(x, "Revision")) //hasta rapor arraylerinin içindeki raproları revision numarasına göre grupluyor
                    return hastaRevisionGroup
                }).then((data) => {
                    console.log(data)
                    setFilterRaporList(data);
                    setRaporList(data);
                })
           .catch(function (error) {
             console.log(error);
           });
    }
    useEffect(() => {
        _getList();
    }, [contextType])
    return <Wrapper>
        <Filter 
        change={_filter} 
        startDate={_startDate}
        endDate={_endDate}
        run={_run}
        disabledvalue={startDate&&endDate?true:false}
        />
        <ul>
            {filterraporlist ? filterraporlist.map((x, i) => {
                return <li key={i}>
                    {x.map((y, z) => {
                        return <div className="wrap" key={z}>
                            <div className="kapsa">
                                {y.map((k, l) => {
                                    return <article key={l}>
                                        {k.XSig ? <FaSignature title="World Health Organization" /> : <MdPictureAsPdf />}
                                        {k.Tarih ? " " + k.Tarih.substring(0, 10) + " " : false}
                                        {k.FileName.replace(".pdf", "").replace(".xsig", "")}
                                        {k.RaporAciklama ? " " + k.RaporAciklama : false}
                                        <div className="button-group">
                                            <button onClick={() => _indir(k.ID)}>
                                                <MdFileDownload />{k.Download>0?k.Download:false}
                                            </button>
                                        </div>
                                    </article>
                                })}
                            </div>
                            {contextType.isPermission ? <button className="deleteButton" onClick={() => _sil([y[0] ? y[0].ID : false, y[1] ? y[1].ID : false])}>
                                <MdDelete />
                            </button> : false}
                        </div>
                    })}


                </li>
            }) : "Yükleniyor..."}
        </ul>
    </Wrapper>
}