import styled from 'styled-components';

export const Wrapper= styled.section`
display:flex;
box-sizing:border-box;
/* height:calc( 100vh - 90px); */
background:#dfe6e9;
flex-direction:row;
justify-content:center;
align-items:flex-start;
flex-basis:50%;
min-height:calc(100vh - 85px);
@media (max-width: 768px) {
    flex-basis:100%;
  } 
ul{
    li{
        list-style:none;
    }
}
`